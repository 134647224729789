import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import RetryButton from '../components/RetryButton'

import LoginSubmissionForm from '../components/Forms/loginSubmissionForm'

import ProductList from '../components/ProductList'

import {
	Breadcrumb,
	Button,
	Icon,
	Container,
	Item,
	Image,
	Header,
	Message,
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import * as franchisestyles from './styles/franchisecontentpage.module.css'
import * as contentpagestyles from './styles/contentpage.module.css'

import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth,
	confirmationColor
} from '../globalVar'

import headingimg from "../images/franchise/welcomefranchisee.png"

const Negosyo = require('../../lib/negosyo')


class FranchiseContentPageTemplate extends React.PureComponent {
	state = {
		loggedIn:undefined,
		pageid: 0,
		record: this.props.data.negosyoNow.viewFranchise,
		recorddetails: {},
		success: "",
		pageLoading:true,
		error:false,
		shown:undefined,
		timeOut1:undefined,
		username: "",
		hasRegistered: false,
		allowRegister: true,
		regMessage: "",
		pageSection:[],

		mobile:undefined,
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				shown: 5,
				mobile:false,
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
				mobile:true,
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true,
			})
		}
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}
	componentDidMount() {
		this.handler();
		this.fetchData();
		window.addEventListener('resize', this.resizeTimerFunc);
	}

	handleSignIn = (username) => {
		// Check if already registered
		if (username.length > 0) {
			this.setState({
				username: username,
			}, function() {
				(async () => {
					const response = await Negosyo.getFranchiseResources(username, this.props.pageContext.url);
					if (response.hasOwnProperty("registered") && response.hasOwnProperty("content")) {
						this.setState({
							hasRegistered: response.registered,
							recorddetails: response.content,
							pageLoading: false
						});
					} else {
						const regresponse = await Negosyo.checkFranchiseRegistered(username, this.props.pageContext.url);
						var regresponsemsg = "You are not yet registered";
						var regallowed = true;
						if (regresponse.hasOwnProperty("registered") && regresponse.hasOwnProperty("message")  && regresponse.hasOwnProperty("allowed")) {
							regresponsemsg = "Application Status:"+regresponse.message;
							regallowed = regresponse.allowed;
						}
						this.setState ({
							hasRegistered: false,
							allowRegister: regallowed,
							regMessage: regresponsemsg,
							pageLoading: false
						});

					}
				})();
			});
		}
	}

	returnPage = () => {
		navigate('/franchise/'+this.props.pageContext.url);
	}

	downloadFile = (index, fname) => {
		if (fname.indexOf("://") > 0) {
			window.open(fname, "_blank");
		} else {
			this.setState({
				["downloading"+index]: true,
			}, function () {
				setTimeout(function() {
					this.setState({
						["downloading"+index]: false,
					});
				}.bind(this), 5000);
				(async () => {
					await Negosyo.getFranchiseFile(this.state.username, this.props.pageContext.url, fname);
				})();

			});
		}
	}

	fetchData = (retry=false) => {
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.handleSignIn(user.username);
				}).catch((err)=>{
					this.invalidAccess();
				})
			} else {
				this.invalidAccess();
			}
		}).catch((err)=>{
			this.invalidAccess();
		})
	}

	invalidAccess = () => {
		this.setState({
			pageLoading:false
		})
	}


	render() {
		const {
			record,
			error,
			pageLoading,
			hasRegistered
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this

		return (

			<Layout location={location} backButton={true}>
				<SEO
					canonicalpath={"/franchise/resource/"+record.url}
					title={record===undefined ? " Resouces Page":record.name}
					image={record.mainImageHref}
					twitterCard={"summary"}
					description={record.summary}
					meta={[{name: `robots`, content: `noindex`}]}
				/>
				<Container text className={styles.container}>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={"Franchise"} as={Link} to={"/franchise/"} />
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={record.name+" Resources"} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						<Breadcrumb.Section as={Link} to={"/franchise/"} >
							<Icon name="arrow left" />
							{"Franchise"}
						</Breadcrumb.Section>
					</Breadcrumb>
					<br/>

					<Item.Image size="massive">
						<Image src={record.contentImage} alt={record.name} />
					</Item.Image>
					<div className={franchisestyles.bannerheading}>
						<h1><img className={franchisestyles.bannerheadingimage} src={headingimg} alt="Welcome Franchisee" /></h1>
						<div>
							<div className={franchisestyles.bannerheadingtext}>{record.contentText}</div>
						</div>
					</div>
					<span id="page-top" className={styles.anchor}/>
					{record.url !== "" &&
						<React.Fragment>
							<Item.Group>
								<Item className={styles.item}>
									<Item.Content>
										<Item.Header className={styles.header}>
											{record.name+" Resources Page"}
										</Item.Header>

										{(hasRegistered && this.state.recorddetails.contentlist.length < 1 && this.state.recorddetails.productlist.length < 1) && <>
											<Item.Description className={styles.text}>
												No content at the moment
											</Item.Description>
										</>}
									</Item.Content>
								</Item>
							</Item.Group>
						</React.Fragment>

					}
					{( pageLoading===true ) && (
						<Message warning className={styles.msg}>
							<Message.Header>
								Loading...
							</Message.Header>
						</Message>
					)}
					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									pageLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Franchise Content"}
						/>
					)}
					{( hasRegistered===false && pageLoading===false ) &&
						<>
						{this.state.username.length > 0 ?
							<Message warning className={styles.msg}>
								<Message.Header>
									{this.state.regMessage}
								</Message.Header>
								<Link to={"/franchise/"+this.props.pageContext.url+"/"}>{this.state.allowRegister?"Click to Register":"OK"}</Link>
							</Message>
						:
							<LoginSubmissionForm
								noUserInfo={true}
								handleSignIn={this.handleSignIn}
							/>
						}
						</>
					}
				</Container>

				{hasRegistered && <>
					{this.state.recorddetails.contentlist.length > 0 &&
						<Container className={styles.container}>
							<Header className={`${styles.header}`}>
								Downloads/Files
							</Header>
							<ul>
								{this.state.recorddetails.contentlist.map((curfile, index)=>{
										if (curfile.url.length < 1) {
											return <li className={styles.header+" "+contentpagestyles.contentsectiontitle}>{curfile.name}</li>
										}
										const filenamelink =  <li>
														{curfile.name}
														&nbsp;&nbsp;&nbsp;
														{this.state['downloading'+index] === true ?
															<>
																...
															</>
														:
															<Button
																icon={"download"}
																onClick={()=>{
																	this.downloadFile(index, curfile.url);
																}}
															/>
														}
													</li>
										if (curfile.hasOwnProperty("thumbnail")) {
											if (curfile.thumbnail.length>0) {
												return <li className={contentpagestyles.contentthumbnail}>
													<Image
														src={curfile.thumbnail}
													/>
													{filenamelink}
												</li>
											}
										}
										return {filenamelink}
									})}
							</ul>
						</Container>
					}
					{this.state.recorddetails.productlist.length > 0 &&
						<Container className={styles.container}>
							<Header className={`${styles.header}`}>
								Exclusive Items
							</Header>
							<ProductList
								products={this.state.recorddetails.productlist}
								shown={this.state.shown}
								grid={16}
								cameFrom={`franchise-${this.state.record.name}`}
								page={0}
								offset={20}
								listName={`franchise-${this.state.record.name}`}
							/>
						</Container>

					}

				</>}
			</Layout>
		)
	}
}

export default FranchiseContentPageTemplate


export const data = graphql`
	query FranchiseContentQuery($url:String!) {
		negosyoNow {
			viewFranchise( url: $url ) {
				id
				name
				summary
				url
				mainImageHref
				contentImage
				contentText
				slug
				organizationName
				organizationUrl
				sku
				available
			}
		}
	}
`

